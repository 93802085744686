<template>
   <div class="col-md-8">
      <UserCallWaitInfo style="margin-bottom:10px;" :height="22" :call_details="call_initiated_details" v-if="call_initiated_details && call_initiated_details.accepted_appointment && !call_initiated_details.accepted_appointment.actual_end_time" @move-to-call-details="moveToCallDetails"/>
      <ClientWaitMessage :client_wait="client_wait" style="margin-bottom:10px;"/>
      <template v-for="(event_list,index) in event_list" :key="index">
         <div class="reccomande-box-data" :style="{'background-image': 'url('+event_list.banner_image+')','float':'left','margin-left':'10px','max-width': '45% !important','min-width': '45% !important','max-height': '41%','min-height': '41%','cursor':'pointer'}" @click.prevent="goToEventView(event_list.id)">
            <div class="recom-linked" >
               <ul class="nav nav-img" v-if = "event_list.event_categories.length || event_list.event_tags.length">
                  <template v-for="(event_category,category_index) in event_list.event_categories" :key="category_index">
                     <li><a class="" href="#" style="text-decoration:none !important; text-transform:capitalize;" v-if="category_index < 1">{{event_category.name}}</a></li>
                  </template>
                  <template v-for="(event_tag,tag_index) in event_list.event_tags" :key="tag_index">
                     <li><a class="" href="#" style="text-decoration:none !important; text-transform:capitalize;" v-if="tag_index < 2">{{event_tag.name}}</a></li>
                  </template>
               </ul>
               <ul class="nav nav-img" v-else>
                  <li><a class="" href="#" style="text-decoration:none !important;">Naqqe</a></li>
               </ul>
               <span>
                  <!--   <h5>Free Event</h5> -->
               </span>
            </div>
            <p class="inner_html ev_css"> <a class="text-white"  style="text-decoration:none !important;width: 50px !important; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"> {{ getEventTitle(event_list.event_title) }}</a></p>
            <div class="rem-pa text-white justify-content mt-4">
               <div class="d-flex align-items-center">
                     <h6 class="text-white" style="color: #fff !important; margin-left:5px;">{{event_list.type}}</h6>
               </div>
            </div>
         </div>
      </template>
      <template v-if = "is_loaded">
         <div class="nb-spinner"></div>
      </template>
   </div>
</template>
<script>
import Auth from '../resources/Auth.js';
import Constant from '../resources/Constant.js';
import ClientWaitMessage from "./ClientWaitMessage.vue";
import UserCallWaitInfo from "./UserCallWaitInfo.vue";
export default {
  props:['client_wait','intial_loaded','call_initiated_details'],
  emits:['moveToCallDetails'],
  components:{
   ClientWaitMessage,UserCallWaitInfo
  },
  methods:{
       moveToCallDetails(details){
           this.$emit('moveToCallDetails',details);
       },
       getFileUrl(file_urls){
         if(file_urls && (file_urls.length > 0)){
            console.log("file",file_urls[0].event_file_data);
            return file_urls[0].event_file_data;
         }else{
            return this.image_link;
         }
       },
       getUpcomingEventList(){

          let pagination_ref = "";
          pagination_ref = "?pageoffset="+this.pageoffset+"&pageno="+this.pageno;
          let getUpcomingEventsList = Constant.getbaseurl()+'/events'+pagination_ref;
          this.axios.get(getUpcomingEventsList)
            .then(({data}) => {
                console.log("is_loaded",this.is_loaded);
                this.is_loaded = false;
                let response_data = data;
                this.event_data = response_data.data;
                this.event_list = response_data.data;
          })
          .catch((error) => {
                if(error.response.status == 401){
                     this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                     Auth.logout();
                     this.$router.push('login');
                 }else{
                     this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                 }
          });
       },
       getEventTitle(title = null){

          let name = title;
          let shortname = title;
          if (name.length > 40) {
               shortname = name.substring(0, 40) + " ...";
          }
          return shortname;
       },
       goToEventView(event_id){
        console.log("event_detail",event_id);
            this.$router.push({
              name: 'event_detail', 
              params: {"event_id":event_id }
            });
       }
  },
  mounted(){
    this.is_loaded = true;
    this.getUpcomingEventList();
  },
  data(){
    return {
      "is_loaded":false,
      "show_control":false,
      "disable_click":true,
      "event_data":null,
      "event_list":[],
      "faq_data":null,
      "faq_list":[],
      "color":"blue",
      "image_link":"https://as1.ftcdn.net/v2/jpg/01/07/66/26/1000_F_107662666_zMxONsgMZY1rm8eshfCGMsSfmRPbMuTB.jpg",
      "pageoffset":4,
      "pageno":1
    }
  }
}
</script>

