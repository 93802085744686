import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import DashboardView from '../views/DashboardView.vue'
import TestView from '../views/TestView.vue'
import Events from '../views/Events.vue'
import EventsView from '../views/EventsView.vue'
import Auth from '../resources/Auth.js'

const routes = [
  {
    path: '/',
    name: 'home',
    component: DashboardView,
    meta: {
            requiresAuth: true
          }
  },
  {
    path: '/test_changes',
    name: 'test_changes',
    component: TestView,
    meta: {
            requiresAuth: true
          }
  },
  {
    path: '/events',
    name: 'events',
    component: Events,
    meta: {
            requiresAuth: true
          }
  },
  {
    path: '/event_detail/:event_id',
    name: 'event_detail',
    component: EventsView,
    meta: {
            requiresAuth: true
          }
  },
  
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
            loginAction: true
    },
  },
  {
    path: '/sessions',
    name: 'sessions',
    meta: {
            requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/SessionView.vue')
  },
  {
    path: '/articles',
    name: 'articles',
    meta: {
            requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ArticleView.vue')
  },
  {
    path: '/my_schedules',
    name: 'my_schedules',
    meta: {
            requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/MyScheduleView.vue')
  },
  {
    path: '/revenue',
    name: 'revenue',
    meta: {
            requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/RevenueView.vue')
  },
  {
    path: '/payouts',
    name: 'payouts',
    meta: {
            requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/PayoutView.vue')
  },
  {
    path: '/notifications',
    name: 'notifications',
    meta: {
            requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/NotificationsView.vue')
  },
  {
    path: '/my_performance',
    name: 'my_performance',
    meta: {
            requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/MyPerformanceView.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    meta: {
            requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/FaqView.vue')
  },
  {
    path: '/termservices',
    name: 'termservices',
    meta: {
            requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsOfServiceView.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    meta: {
            requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyPolicyView.vue')
  },
  {
    path: '/tips',
    name: 'tips',
    meta: {
            requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/TipsView.vue')
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    meta: {
            requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutUsView.vue')
  },
  {
    path: '/my_profile',
    name: 'my_profile',
    meta: {
            requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/MyProfileView.vue')
  },
  {
    path: '/edit_availabilty',
    name: 'edit_availabilty',
    meta: {
            requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/EditAvailabiltyView.vue')
  },
  {
    path: '/session_call/:appointment_id',
    name: 'session_call',
    meta: {
            requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/SessionCallView.vue')
  },
  {
    path: '/resources',
    name: 'resources',
    meta: {
            requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ResourcesView.vue')
  },
  {
    path: '/edit_profile',
    name: 'edit_profile',
    meta: {
            requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/MyProfileUpdateView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
    console.log("requiresAuth",Auth.check());
    if (to.matched.some(record => record.meta.requiresAuth) ) {
        if (Auth.check()) {
            next();
            return;
        } else {
            router.push('login');
        }
    } else {
      if(to.name == 'login' && Auth.check()){
            router.push('/');
      }else{
       next();
      }
    }
});


export default router
