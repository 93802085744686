<template>
   <div class="home">
      <HeaderPage available_status="available_status" :current_loaded_panel="current_loaded_panel" @update-sidebar-status="updateSidebarStatus"/>
      <div :class="current_class">
         <div class="custom-container">
            <div class="dashboad-page-wrapper">
               <SidebarPage :current_loaded_panel="current_loaded_panel"/>
               <div class="right-bar">
                  <div class="row">
                     <EventsPage :client_wait="client_wait" intial_loaded="intial_loaded" :call_initiated_details="call_initiated_details" @move-to-call-details="moveToCallDetails"/>
                     <div class="col-md-4">
                        <UserInfoPage :available_status="available_status"/>
                        <CalenderPage/>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <FooterPage/>
   </div>
</template>
<script>
   // @ is an alias to /src
   import HeaderPage from '@/components/HeaderPage.vue'
   import SidebarPage from '@/components/SidebarPage.vue'
   import UserInfoPage from '@/components/UserInfoPage.vue'
   import CalenderPage from '@/components/CalenderPage.vue'
   import FooterPage from '@/components/FooterPage.vue'
   import EventsPage from '@/components/EventsPage.vue'
   
   export default {
    props:['available_status','client_wait','intial_loaded','call_initiated_details','current_loaded_panel'],
    emits:['moveToCallDetails','updateSidebarStatus'],
     name: 'TipsView',
     components: {
       HeaderPage,UserInfoPage,CalenderPage,SidebarPage,FooterPage,EventsPage
     },
     methods:{
      moveToCallDetails(details){
        this.$emit('moveToCallDetails',details);
      },
      updateSidebarStatus(status){
         this.$emit('updateSidebarStatus',status)
         if(status == 'full'){
              this.current_class = this.collapsed_open_class;
         }else{
              this.current_class = this.collapsed_class;
         }
      },
     },
     data(){
       return {
         current_class:(this.current_loaded_panel == 'full' ? 'dashboad-page' : 'dashboad-page open'),
         collapsed_open_class:'dashboad-page',
         collapsed_class:'dashboad-page open',
       }
     }
   }
</script>