<template>
   <header class="page-header">
      <div class="custom-container">
         <div class="row">
            <div class="col-md-6">
               <div class="header-left">
                  <div class="logo" v-if="current_status == 'full'">
                     <Transition name="slide-fade">
                     <a href="#" @click.prevent="goToHome"><span><img src="../assets/images/logo.svg"></span></a>
                     </Transition>
                  </div>
                  <div class="header-home" v-if="current_status != 'full'">
                     <Transition name="slide-fade">
                        <a href="#" @click.prevent="goToHome"><span><img src="../assets/images/home-ic.svg"></span></a>
                     </Transition>
                  </div>
                  <div class="side-nav" style="cursor:pointer;" @click.prevent="changeSidebarList">
                     <img src="../assets/images/side-nav.svg">
                  </div>
                <!--   <div class="mobile-online">
                     <div class="user-accounts">
                         <img src="" alt=""/>
                     </div>
                     <div class="switch-btn mobile-switch">
                         <span class="rd" style="text-transform: capitalize;color: green;" v-if="is_available_now">online</span>
                         <span class="rd" style="text-transform: capitalize;color: red;" v-if="!is_available_now">offline</span>
                         <label class="switch">
                             <input type="checkbox" :checked="is_available_now" @change="changeAvailabilty" v-model="is_available_now">
                             <span class="slider round"></span>
                         </label>
                     </div>
                  </div> -->
               </div>
            </div>
            <div class="col-md-6">
               <div class="header-right">
              <!--     <div class="form-group">
                     <input type="text" name="" placeholder="Search…">
                     <img src="../assets/images/search-btn.svg">
                  </div> -->
                  <div class="profile_icon" >
                     <a href="#" v-if="current_live_status"><span><img :src="user.profile_image"></span><label></label></a>
                     <a href="#" v-else><span><img :src="user.profile_image"></span><label style="background: red;"></label></a>
                  </div>
                  <div class="notification">
                     <a href="#" @click.prevent="notification" ><span><img src="../assets/images/bell.svg"></span></a>
                  </div>
                  <div class="notebook">
                     <a  href="#" data-bs-toggle="modal" data-bs-target="#log-out"><span><img src="../assets/images/book.svg"></span></a>
                  </div>
              <!--     <div class="side-nav">
                     <img src="../assets/images/side-nav.svg">
                  </div> -->
               </div>
            </div>
         </div>
      </div>
   </header>
</template>
<script>

   import Auth from '../resources/Auth.js';
   export default {
      props:['current_loaded_panel','available_status'],
      emits:['updateSidebarStatus'],
      mounted(){
        console.log("available_status now",this.available_status);
          //console.log("available_status",this.available_status);
      },
      data(){
         return {
            'current_status':this.current_loaded_panel,
            'current_live_status':this.available_status,
            'user' : window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null, 
         }
      },
      watch: {
       current_status(new_status) {
         this.updateSidebarStatus(new_status);
       },
       available_status(new_status){
         console.log("current_live_status",new_status);
          this.current_live_status = new_status;
       }
     },
      methods: {
                  logout() {
                      Auth.logout(); //set local storage
                      this.$swal({icon: 'success', title: "Logout Successfully"});
                      this.$router.push('login');
                  },
                  notification() {
                       this.$router.push('notifications');
                  },
                  changeSidebarList(){

                     if(this.current_status == 'full'){
                        this.current_status = "closed";
                     }else {
                        this.current_status = "full";
                     }
                  },
                  updateSidebarStatus(new_status){
                     this.$emit('updateSidebarStatus',new_status);
                  },
                  goToHome() {
                     this.$router.push('/')
                  },
      }
   }
</script>
<style type="text/css" scoped>
.slide-fade-enter-active {
     transition: all 10s ease-out;
}

.slide-fade-leave-active {
  transition: all 20s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.profile_icon span {
    width: 60px;
    height: 60px;
    background: #2BBF76;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
}
.profile_icon span img{
    width: 60px;
    height: 60px;
    border-radius: 100px;
}
.profile_icon {
    position: relative;
}
.profile_icon label {
    position: absolute;
    top: -4px;
    width: 17px;
    height: 17px;
    background: #2bbf76;
    border-radius: 100px;
    right: -2px;
    border: 2px solid #fff;
}
.profile_icon {
    margin: 0 25px;
}
</style>